import { BaseElement, html, css, formCss } from 'Elements';
import dayjs from 'dayjs';
import 'evil-datepicker';

class SelectDatePeriods extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        /*
        modal-dialog::part(base) {
          font-size: var(--sl-font-size-medium);
          line-height: 10px;
          padding: 0px;
        }
        */

        sl-option::part(base) {
          font-size: 1em;
        }
      `
    ]
  }

  static get properties() {
    return {
      localStorageKey: { type: String },
      defaultValue: { type: String },
      value: { type: String },
      customPeriod: { type: Boolean },
      size: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            'period': 'Period',
            'nodate': 'No date',
            '1h': 'Current hour',
            '1hg': 'Less than an hour',
            '1d': 'Today',
            '1dg': 'Less than 24 hours',
            '1w': 'This week',
            '1wg': 'Less than a week',
            '1m': 'Current month',
            '1mg': 'Less than a month',
            '1y': 'Current year',
            '1yg': 'Less than a year',
            'range': 'Specific dates',
          },
        },
        french: {
          translation: {
            'period': 'Période',
            'nodate': 'Date indifférente',
            '1h': 'Heure en cours',
            '1hg': 'Moins d\'une heure',
            '1d': 'Aujourd\'hui',
            '1dg': 'Moins de 24 heures',
            '1w': 'Cette semaine ',
            '1wg': 'Moins d\'une semaine',
            '1m': 'Mois en cours',
            '1mg': 'Moins d\'un mois',
            '1y': 'Année en cours',
            '1yg': 'Moins d\'un an',
            'range': 'Dates précises',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.size = 'small';
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);

    this.customPeriod = false;
    
    this.allowedDates = [
      //{ value:'nodate' },
      //{ value:'1h' },
      //{ value:'1hg' },
      { value:'1d' },
      //{ value:'1dg' },
      { value:'1w' },
      //{ value:'1wg' },
      { value:'1m' },
      //{ value:'1mg' },
      { value:'1y' },
    ]

    if (this.customPeriod) {
      this.allowedDates.push({ value:'range', label:this._tl('range') });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  onLanguageChanged() {
    this._log.debug('onLanguageChanged');
    this.requestUpdate();
  }

  getDatesRange(value) {
    let start, end;

    if (!value) return null;

    switch (value) {
      case '1h': start = dayjs().startOf('hour');         end = start.add(1, 'hour');  break;
      case '1d': start = dayjs().startOf('day');          end = start.add(1, 'day'); break;
      case '1w': start = dayjs().startOf('week');         end = start.add(1, 'week'); break;
      case '1m': start = dayjs().startOf('month');        end = start.add(1, 'month'); break;
      case '1y': start = dayjs().startOf('year');         end = start.add(1, 'year'); break;
      case '1hg': start = dayjs().subtract(1, 'hour');    end = dayjs(); break;
      case '1dg': start = dayjs().subtract(1, 'day');     end = dayjs(); break;
      case '1wg': start = dayjs().subtract(7, 'day');     end = dayjs(); break;
      case '1mg': start = dayjs().subtract(1, 'month');   end = dayjs(); break;
      case '1yg': start = dayjs().subtract(12, 'month');   end = dayjs(); break;
    }

    start = start.toISOString();
    end = end.toISOString();

    return { start, end };
  }

  formatDateRangeSelectorTitle(start, end) {
    this.popupDateRangeSelector.setAttribute('label', dayjs(start).format('DD/MM/YYYY') + ' - ' + dayjs(end).format('DD/MM/YYYY'));
  }

  closeDateRangeSelector() {
    this._log.debug('closeDateRangeSelector');
    this.popupDateRangeSelector.hide();
  }

  handlePeriodChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.period = this.getDatesRange(e.target.value);
    this.value = e.target.value;
    this.sendEvent();
  }

  sendEvent() {
    const detail = { value:this.value }
    if (this.period) {
      detail.start = this.period.start;
      detail.end = this.period.end;
    }

    const customEvent = new CustomEvent('change', { detail });
    this.dispatchEvent(customEvent);
  }

  checkToOpenDateRangeSelector(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.target.value === 'range') {
      this.openDateRangeSelector();
    }
  }

  onDateClick(e) {
    this._log.debug('onDateClick', e);
  }

  render() {
    this._log.debug('render', this.value);
    return html`
      <sl-select
        hoist
        clearable
        placeholder="${this._tl('period')}"
        value="${this.value}"
        @sl-change="${e => this.handlePeriodChange(e)}"
        size="${this.size}"
      >
        ${this.allowedDates.map(date => html`
          <sl-option 
            size="small" 
            value="${date.value}" 
            ?selected="${this.value === date.value}"
          >${this._tl(date.value)}</sl-option>`)
        }
      </sl-select>
    `;
  }
}

customElements.define('select-date-periods', SelectDatePeriods);