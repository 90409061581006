import { BaseElement, html, css, formCss } from 'Elements';
import dayjs from 'dayjs';
import 'evil-datepicker';

class SelectDateRange extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        /*
        modal-dialog::part(base) {
          font-size: var(--sl-font-size-medium);
          line-height: 10px;
          padding: 0px;
        }
        */

        sl-option::part(base) {
          font-size: var(--sl-font-size-small);
        }
      `
    ]
  }

  static get properties() {
    return {
      localStorageKey: { type: String },
      defaultValue: { type: String, reflect: true },
      value: { type: String, reflect: true },
      customPeriod: { type: Boolean, reflect: true },
      size: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            'period': 'Period',
            'nodate': 'No date',
            '1h': 'Current hour',
            '1hg': 'Less than an hour',
            '1d': 'Today',
            '1dg': 'Less than 24 hours',
            '1w': 'This week',
            '1wg': 'Less than a week',
            '1m': 'Current month',
            '1mg': 'Less than a month',
            '1y': 'Current year',
            '1yg': 'Less than a year',
            'range': 'Specific dates',
          },
        },
        french: {
          translation: {
            'period': 'Période',
            'nodate': 'Date indifférente',
            '1h': 'Heure en cours',
            '1hg': 'Moins d\'une heure',
            '1d': 'Aujourd\'hui',
            '1dg': 'Moins de 24 heures',
            '1w': 'Cette semaine ',
            '1wg': 'Moins d\'une semaine',
            '1m': 'Mois en cours',
            '1mg': 'Moins d\'un mois',
            '1y': 'Année en cours',
            '1yg': 'Moins d\'un an',
            'range': 'Dates précises',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.size = 'small';
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.closeDateRangeSelector = this.closeDateRangeSelector.bind(this);
    this.openDateRangeSelector = this.openDateRangeSelector.bind(this);
    this.checkToOpenDateRangeSelector = this.checkToOpenDateRangeSelector.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.onDateRangeClick = this.onDateRangeClick.bind(this);

    this.customPeriod = true;
    
    this.allowedDates = [
      //{ value:'nodate' },
      //{ value:'1h' },
      //{ value:'1hg' },
      { value:'1d' },
      //{ value:'1dg' },
      { value:'1w' },
      //{ value:'1wg' },
      { value:'1m' },
      //{ value:'1mg' },
      { value:'1y' },
    ]

    if (this.customPeriod) {
      this.allowedDates.push({ value:'range', label:this._tl('range') });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChanged);

    if (this.localStorageKey) {
      let tmp = localStorage.getItem(this.localStorageKey);
      if (!tmp) {
        this.value = this.defaultValue;
      } else {
        try {
          tmp = JSON.parse(tmp);
          this.value = tmp.value;
          this.period = tmp.period;
        } catch(e) {
          this.value = this.defaultValue;
        }
      }
    } else {
      this.value = this.defaultValue;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  onLanguageChanged() {
    setTimeout(async () => {
      // force sl-select to refresh his selected value
      const pValue = this.value;
      this.value = '';
      await this.requestUpdate();
      this.value = pValue;
      await this.requestUpdate();
    }, 1);
  }


  getDatesRange(value) {
    let start, end;

    switch (value) {
      case '1h': start = dayjs().startOf('hour');         end = start.add(1, 'hour');  break;
      case '1d': start = dayjs().startOf('day');          end = start.add(1, 'day'); break;
      case '1w': start = dayjs().startOf('week');         end = start.add(1, 'week'); break;
      case '1m': start = dayjs().startOf('month');        end = start.add(1, 'month'); break;
      case '1y': start = dayjs().startOf('year');         end = start.add(1, 'year'); break;
      case '1hg': start = dayjs().subtract(1, 'hour');    end = dayjs(); break;
      case '1dg': start = dayjs().subtract(1, 'day');     end = dayjs(); break;
      case '1wg': start = dayjs().subtract(7, 'day');     end = dayjs(); break;
      case '1mg': start = dayjs().subtract(1, 'month');   end = dayjs(); break;
      case '1yg':start = dayjs().subtract(12, 'month');   end = dayjs(); break;
    }

    return { start, end };
  }

  onDateRangeClick(e) {
    this._log.debug('onDateRangeClick', e);
  }

  formatDateRangeSelectorTitle(start, end) {
    this.popupDateRangeSelector.setAttribute('label', dayjs(start).format('DD/MM/YYYY') + ' - ' + dayjs(end).format('DD/MM/YYYY'));
  }

  closeDateRangeSelector() {
    this._log.debug('closeDateRangeSelector');
    this.popupDateRangeSelector.hide();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async openDateRangeSelector() {
    // Créer un modal-dialog avec un wc-date-picker à l'intérieur
    const modalId = 'modal-date-selector';
    this.popupDateRangeSelector = this.popupDateRangeSelector || document.getElementById(modalId) || this.shadowRoot.getElementById(modalId);
    this.popupDateRangeSelector.label = '';
    
    const start = new Date();
    const end = new Date();

    //const datePicker = this.popupDateRangeSelector.querySelector('wc-date-picker');
    //datePicker.addEventListener('date-click', this.onDateClick);

    this.formatDateRangeSelectorTitle(start, end);
    this.popupDateRangeSelector.show();
  }

  handlePeriodChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.value = e.target.value;
    this.period = this.getDatesRange(e.target.value);
    if (e.target.value === 'range') {
      //this.openDateRangeSelector();
      return;
    }

    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, JSON.stringify({ value:this.value, period:this.period }));
    }

    const changeEvent = new CustomEvent('sl-change', {
      detail: { period: this.period, value: this.value },
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(changeEvent);
  }

  checkToOpenDateRangeSelector(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.target.value === 'range') {
      this.openDateRangeSelector();
    }
  }

  onDateClick(e) {
    this._log.debug('onDateClick', e);
  }

  render() {
    return html`
      <sl-select hoist placeholder="${this._tl('period')}" value="${this.value}" @sl-change="${e => this.handlePeriodChange(e)}" size="${this.size}">
        ${this.allowedDates.map(date => html`
          <sl-option 
            size="small" 
            value="${date.value}" 
            ?selected="${this.value === date.value}"
            @click=${this.checkToOpenDateRangeSelector}
          >${this._tl(date.value)}</sl-option>`)}
      </sl-select>

      <modal-dialog id="modal-date-selector">
        
        <evil-datepicker
          range="true"
          locale="fr"
          position="manual"
          @date-click=${this.onDateClick}
          start=""
          end=""
        ></evil-datepicker>
        <sl-button slot="bt1" variant="primary" @click=${this.closeDateRangeSelector}>Close</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('select-date-range', SelectDateRange);