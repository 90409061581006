import { LitElement, html, css } from 'lit-element';

class PrettyJson extends LitElement {
  static get properties() {
    return {
      jsonData: { type: String },
      data: { type: Object },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          font-family: monospace;
          padding-left:10px;
          display:block;
        }

        .json-key {
          font-weight: bold;
        }

        .json-value {
          color: green;
        }

        .json-string {
          color: red;
        }
        
        .json-toggle {
          padding-top: 2px;
          display: block;          
          cursor: pointer;
        }

        .toggle-icon {
          font-size:1em;
        }

        .json-object,
        .json-array {
          padding-left: 13px;
          border-left: 2px solid #ddd; /* Barre verticale pour chaque niveau */
        }
        .json-content {
          padding-left: 2px; /* Indentation supplémentaire pour les enfants */
        }

        .child-count {
          color:var(--sl-color-neutral-400);
        }
      `
    ];
  }

  constructor() {
    super();
    this.jsonData = null;
    this.iconExpand = '+';
    this.iconCollapse = '-';
  }

  connectedCallback() {
    super.connectedCallback();
    const slotContent = this.textContent.trim();
    if (slotContent) {
      try {
        this.jsonData = JSON.parse(slotContent);
      } catch (e) {
        console.error('Invalid JSON passed in slot:', e);
      }
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('jsonData')) {
      if (typeof this.jsonData === 'string') {
        try {
          const slotContent = this.textContent.trim();
          this.parsedJson = JSON.parse(slotContent);
        } catch (e) {
          console.error('Invalid JSON passed in jsonData:', e);
        }
      } else {
        this.parsedJson = { ...this.jsonData };
      }

      this.requestUpdate();
    }

    if (changedProperties.has('data')) {
      this.parsedJson = { ...this.data };
      this.requestUpdate();
    }
  }

  toggle(event) {
    const parent = event.target.closest('.json-toggle').parentElement;
    const children = parent.querySelector('.json-content');
    const toggleIcon = parent.querySelector('.toggle-icon');

    if (children) {
      const isHidden = children.style.display === 'none';
      children.style.display = isHidden ? 'block' : 'none';
      if (toggleIcon) {
        toggleIcon.textContent = isHidden ? this.iconCollapse : this.iconExpand;
      }
    }
  }

  renderJson(json) {
    if (json && typeof json === 'object') {
      const entries = Array.isArray(json) ? json.entries() : Object.entries(json);
      return html`
        ${Array.from(entries).map(([key, value]) => {
          const isExpandable = typeof value === 'object' && value !== null;
          const childCount = isExpandable ? (Array.isArray(value) ? value.length : Object.keys(value).length) : 0;
          return html`
            <div class="${Array.isArray(json) ? 'json-array' : 'json-object'}">
              ${isExpandable ? html`
                <span class="json-toggle" @click="${this.toggle}">
                  <span class="toggle-icon">${this.iconExpand}</span>
                  <span class="json-key">${Array.isArray(json) ? `[${key}]` : key}:</span>
                  <span class="child-count">(${childCount})</span>
                </span>
              ` : html`
                <span class="toggle-icon">&nbsp;</span>
                <span class="json-key">${Array.isArray(json) ? `[${key}]` : key}:</span>
              `}
              ${isExpandable ? html`
                <div class="json-content">
                  ${this.renderJson(value)}
                </div>
              ` : html`
                <span class="json-value">${JSON.stringify(value)}</span>
              `}
            </div>
          `;
        })}
      `;
    } else {
      return html`
        <span class="json-string">${json}</span>
      `;
    }
  }

  render() {
    return html`
      ${this.parsedJson ? this.renderJson(this.parsedJson) : html`<slot></slot>`}
    `;
  }
}

customElements.define('pretty-json', PrettyJson);