import { BaseElement, html, css } from 'Elements';

class SectionHeader extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display:block;
          margin:0px;
          padding:0px;
        }

        .header {
          display:flex;
          align-items: center;
          padding:0px;
          padding-left:5px;
          overflow:hidden;
          padding-bottom:3px;
          min-height:35px;
        }

        .header > * {
          display:flex;
          align-items: center;
        }

        .big {
          font-weight: 600;
          font-size: 1.7em;
          text-transform: uppercase;
          border-bottom:1px solid var(--sl-color-neutral-400);
        }

        .medium {
          font-size: 1.3em;
          font-weight: 600;
          border-bottom:1px solid var(--sl-color-neutral-300);
          text-transform: uppercase;
        }

        .normal {
          font-size: 1.1em;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        .small {
          font-size: 0.9em;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        .vsmall {
          font-size: 0.8em;
          border-bottom:1px solid transparent;
        }

        .center {
          flex: 1;
        }

        .left {
          margin-right: 6px;
          padding-top: 2px;
        }

        .header.bold {
          font-weight: 600;
        }

        ::slotted(img) {
          margin-right: 10px;
          max-height: 25px;
        }

        ::slotted(sl-dropdown) {
          margin-top: -5px;
        }

        m-icon[name="arrow_back_ios"] {
          opacity:0.5;
          transition: opacity 0.3s;
        }

        m-icon[name="arrow_back_ios"]:hover {
          opacity:1;
        }

        .blue {
          color: var(--nsys-blue3);
        }

        .bold {
          font-weight: bold;
        }

        slot[name="right"] {
          display:flex;
          gap:8px;
          margin-right:10px;
        }

        /* iphone 8 */
        @media (max-width: 670px) {
          :host {
            font-size:0.8em;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      size: { type: String },
      bold: { type: Boolean },
      backroute: { type: String },
      aroute: { type: String },
      micon: { type: String },
      img: { type: String },
      iheight: { type: String },
      variant: { type: String }
    }
  }

  constructor() {
    super();
    this.size = 'big';
    this.bold = false;
    this.micon = '';
    this.aroute = '';
    this.img = '';
    this.iheight = '27px';
    this.variant = '';
  }

  render() {
    return html`
      <div class="header ${this.variant} ${this.size} ${this.bold ? 'bold' : ''}">
        <div class="left">
          ${this.backroute 
            ? html`<m-icon aroute="${this.backroute}" name="arrow_back_ios"></m-icon>`
            : ''
          }
          ${this.micon 
            ? this.aroute 
              ? html`<m-icon aroute="${this.aroute}" name="${this.micon}"></m-icon>`
              : html`<m-icon name="${this.micon}"></m-icon>`
            : ''
          }
          ${this.img 
            ? html`<img src="${this.img}" height="${this.iheight}"></img>`
            : ''
          }
          <slot name="left"></slot>
        </div>
        <div class="center ${this.bold}">
          ${this.aroute 
            ? html`<a-route href="${this.aroute}"><slot></slot></a-route>`
            : html`<slot></slot>`
          }
        </div>
        <div class="right"><slot name="right"></slot></div>
      </div>
    `;
  }

  //${this.size === 'big' ? html`<hr-custom></hr-custom>`: ''}

}

customElements.define('section-header', SectionHeader);